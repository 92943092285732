<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Score Application Response » <em>{{ applicantTitle }}</em>
            </h3>
          </b-card-header>
          <b-row
            v-for="(section, i) in questions"
            :key="i"
          >
            <b-col><h4 class="p-1 mb-2 rounded bg-light">
              {{ i }}
            </h4></b-col>
            <b-col
              class="px-2"
              cols="12"
            >
              <b-row
                v-for="(question, index) in section"
                :key="index"
              >
                <b-col>
                  <b-form-group
                    :label="`Q${index+1}. ${question.question}`"
                    :label-for="`question-${index}`"
                    label-class="h5"
                  >
                    <h6
                      v-if="question.programs_applicationresponsetables[0]"
                      class="font-italic"
                    >
                      A. <span
                        v-if="question.input_type.toLowerCase() === 'document' && question.programs_applicationresponsetables[0].response"
                      >"File type: <strong>.{{
                        JSON.parse(question.programs_applicationresponsetables[0].response).split('.').pop()
                      }}</strong>"</span>
                      <span v-else>{{
                        question.programs_applicationresponsetables[0].response
                      }}</span>
                      <Promised
                        v-if="question.input_type.toLowerCase() === 'document'
                          && question.programs_applicationresponsetables[0].response"
                        :promise="getLinkFromS3(question.programs_applicationresponsetables[0].response)"
                      >
                        <template #pending>
                          <b-button
                            class="mr-50"
                            disabled
                            size="sm"
                            variant="primary"
                          >
                            <feather-icon icon="ClockIcon" />
                          </b-button>
                        </template>
                        <template #default="data">
                          <b-button
                            :href="data"
                            class="inline mx-50"
                            size="sm"
                            target="_blank"
                            variant="outline-secondary"
                          >View File
                          </b-button>
                        </template>
                        <template #rejected>
                          <span v-b-tooltip="`File not found`">
                            <b-button
                              class="mr-50"
                              disabled
                              size="sm"
                              variant="outline-danger"
                            ><feather-icon icon="AlertTriangleIcon" /></b-button>
                          </span>
                        </template>
                      </Promised>
                    </h6>
                    <h6
                      v-else
                      class="font-italic"
                    >
                      No Response
                    </h6>
                  </b-form-group>
                  <b-form-group
                    v-if="question.programs_applicationresponsetables[0]"
                    :label-for="`score-${index}`"
                    label="Score"
                  >
                    <b-form-rating
                      :id="`score-${index}`"
                      v-model="question.programs_applicationresponsetables[0].programs_applicationdirectscores[0].score"
                      :readonly="readonly"
                      :variant="readonly ? '' : 'primary'"
                      inline
                      required
                      show-value
                      show-value-max
                      stars="10"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="question.programs_applicationresponsetables[0] && question.programs_applicationresponsetables[0].programs_applicationdirectscores[0].score"
                    :label-for="`comment-${index}`"
                    label="Comments"
                  >
                    <b-form-textarea
                      v-model="question.programs_applicationresponsetables[0].programs_applicationdirectscores[0].comment"
                      placeholder="Comments (Optional)"
                      max-rows="6"
                      :disabled="readonly"
                    />
                  </b-form-group>
                  <hr>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-2 d-flex align-items-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="readonly"
                class="mr-2 mt-1 py-1"
                variant="primary"
                @click="$bvModal.show('recommendationModal')"
              >
                <span>Update Score</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
      <b-modal
        v-if="recommendationData"
        id="recommendationModal"
        no-close-on-backdrop
        ok-title="Submit"
        title="Recommendations"
        @ok="updateAnswer"
      >
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="recommendationData.recommendations"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="recommendationData.verdict"
            :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
            placeholder="Select from list"
          />
        </b-form-group>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { Promised } from 'vue-promised'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRating,
    vSelect,
    BButton,
    BCard,
    BCardHeader,
    BFormTextarea,
    Promised,
  },
  data() {
    return {
      review_status: 'disabled',
      applicationData: '',
      mutationLoading: false,
    }
  },
  computed: {
    readonly() {
      return this.review_status !== 'enabled' || this.$route.query.readonly === 'true'
    },
    questions() {
      return this.groupByKey(this.applicationData, 'section')
    },
    /* eslint-disable no-param-reassign, */
    scores() {
      const arr = []
      this.applicationData.map(r => {
        const res = { ...r.programs_applicationresponsetables[0] }
        // res.programs_applicationdirectscores[0].partner_id = res.programs_applicationdirectscores[0].partner_id || this.$route.params.rid
        return arr.push(res)
      })
      arr.forEach(x => {
        x.programs_applicationdirectscores = {
          on_conflict: {
            constraint: 'programs_applicationdirectscores_pkey',
            update_columns: ['score', 'comment'],
          },
          data: x.programs_applicationdirectscores,
        }
      })
      return arr
    },
  },
  methods: {
    nanSafe(n) {
      // Return 0 if NaN
      return Number.isNaN(n) ? 0 : n
    },
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    updateAnswer() {
      this.mutationLoading = true
      this.recommendationData.direct_score = (this.scores.reduce((previousValue, currentValue) => previousValue + this.nanSafe(Number.parseInt(currentValue.programs_applicationdirectscores.data[0].score, 10)), 0) / this.scores.length).toFixed(2)
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_applicationresponsetable_insert_input!]!, $object: programs_applicationscorestable_insert_input!) {
          insert_programs_applicationresponsetable(objects: $answers, on_conflict: {constraint: programs_applicationresponsetable_pkey, update_columns: score}) {
            affected_rows
          }
          insert_programs_applicationscorestable_one(object: $object, on_conflict: {constraint: programs_applicationscorestable_pkey, update_columns: [direct_score, recommendations, verdict]}) {
            id
          }
        }`,
        variables: {
          answers: this.scores,
          object: this.recommendationData,
        },
        update: (store, { data: { insert_programs_applicationresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_applicationresponsetable.affected_rows ? 'Scores submitted successfully' : 'Failed to submit scores',
              icon: insert_programs_applicationresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_applicationresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.pid}}}) {
            id,
            programs_applicationformstables(where: {id: {_eq: ${this.$route.params.apid}}}) {
              round_number
              title
              review_status
              programs_applicationsquestionstables(order_by: {id: asc}) {
                id
                input_type
                question
                section
                programs_applicationresponsetables(where: {applicant_id: {_eq: ${this.$route.params.aid}}}) {
                  id
                  response
                  applicant_id
                  programs_applicationdirectscores(where: {partner_id: {_eq: ${this.$route.params.rid}}}) {
                    id
                    score
                    comment
                    partner_id
                  }
                }
              }
            }
          }
        }`
      },
      update(data) {
        this.review_status = data.programs_basicinfo[0]?.programs_applicationformstables[0]?.review_status
        const response = data.programs_basicinfo[0]?.programs_applicationformstables[0]?.programs_applicationsquestionstables
        response.forEach(e => {
          if (e.programs_applicationresponsetables[0]?.programs_applicationdirectscores.length < 1) {
            e.programs_applicationresponsetables[0].programs_applicationdirectscores.push({
              score: null,
              comment: null,
              partner_id: this.$route.params.rid,
            })
          }
        })
        return response
      },
    },
    recommendationData: {
      query() {
        return gql`
        {
          programs_applicationscorestable(where: {applicant_id_id: {_eq: ${this.$route.params.aid}}, application_form_id: {_eq: ${this.$route.params.apid}}, partner_id: {_eq: ${this.$route.params.rid}}}) {
            id
            verdict
            direct_score
            recommendations

          }
        }`
      },
      update(data) {
        return data.programs_applicationscorestable[0] || {
          applicant_id_id: this.$route.params.aid,
          application_form_id: this.$route.params.apid,
          partner_id: this.$route.params.rid,
          verdict: '',
          recommendations: '',
          direct_score: 0,
        }
      },
    },
    applicantTitle: {
      query() {
        return gql`{
          users_organizationtable(where: {programs_applicantstables: {id: {_eq: ${this.$route.params.aid} }}}) {
            title
          }
        }`
      },
      update(data) {
        return data.users_organizationtable[0].title
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
